import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { formatDateForLoyalty } from "../../utils/formatDateForLoyalty";
import { getCustomerLoyaltyAccess } from "../../utils/getCustomerLoyaltyAccess";
import { CUSTOMER_ACCESS_VIEW } from "../../constants";
import styles from "./AsosWorldBox.scss";

const AsosWorldBox = () => {
  const { getTranslation, product } = useRuntimeConstants();
  const { customerAccess, endDate } = getCustomerLoyaltyAccess(product);

  if (customerAccess !== CUSTOMER_ACCESS_VIEW) {
    return null;
  }

  return (
    <div className={styles.asosWorldBox}>
      <div className={styles.icon}>
        <div className="product-asos-world" />
      </div>
      <p className={styles.description}>
        <span>{getTranslation("early_access_product_tier_one")}</span>
        <span>{getTranslation("early_access_product_tier_one_purplebox")}</span>
      </p>
      <p className={styles.date}>
        {getTranslation("early_access_product_tier_one_date", {
          endDate: formatDateForLoyalty(endDate),
        })}
      </p>
    </div>
  );
};

export default AsosWorldBox;
