import ReactDOM from "react-dom";
import loadable from "@loadable/component";
import getWindow from "../utils/window";
import ErrorBoundary from "../components/errorBoundary/ErrorBoundary";
import PriceData from "../components/priceData/PriceData";
import ProductName from "../components/productName/ProductName";
import ClientOnly from "../containers/clientOnly/ClientOnly";
import OutOfStockLabel from "../components/outOfStockLabels/OutOfStockLabel";
import { ProductContextProvider } from "../context/product/ProductContext";
import { VariantContextProvider } from "../context/variant/VariantContext";
import ProductColourContainer from "../components/productColourContainer/ProductColourContainer";
import ProductPromo from "../components/productPromo/ProductPromo";
import ReleaseDateBanner from "../components/releaseDateBanner/ReleaseDateBanner";
import RepeatedTextBanner from "../components/repeatedTextBanner/RepeatedTextBanner";
import AsosWorldBox from "../components/asosWorldBox/AsosWorldBox";
import ProductSize from "../components/productSize/ProductSize";
import RatingComponent from "../components/ratingComponents/ratingComponent/RatingComponent";
import { PRODUCT_COLOUR_POSITIONS } from "../constants";
import ProductCode from "../components/productCode/ProductCode";
import CustomerFitRating from "../components/customerFitRating/CustomerFitRating";
import VariantSelectionContainer from "../containers/variantSelection/VariantSelectionContainer";
import { SizingContextProvider } from "../context/sizing/SizingContext";
import DeliveryAndReturnsReactContainer from "../containers/deliveryAndReturnsReactContainer/DeliveryAndReturnsReactContainer";
import IfInStock from "../components/conditional/IfInStock";
import StockPriceRetry from "../components/stockPriceRetry/StockPriceRetry";
import LiveRegion from "../components/liveRegion/LiveRegion";
import ProductDescription from "../components/productDescription/ProductDescription";
import IngredientsContainer from "../containers/IngredientsContainer/IngredientsContainer";
import SkeletonPrimaryActions from "../components/skeletonPrimaryActions/SkeletonPrimaryActions";
import { useStockPrice } from "../context/stockPrice/StockPriceContext";
import { useRuntimeConstants } from "../context/runtimeConstants/RuntimeConstantsContext";
import { useIsInStockContext } from "../context/isInStock/IsInStockContext";
import IfStockStateReady from "../components/conditional/IfStockStateReady";
import ShowOnProductStockStatus from "../components/conditional/ShowOnProductStockStatus";
import {
  fireAddToBagAnalytics,
  fireAddToBagErrorAnalytics,
} from "../analytics/addToBag";
import { fireAddToBagMarketing } from "../utils/marketingTracking/marketingTrackingEvents";
import fireNotifyMeClickAnalytics from "../analytics/backInStock";
import fireSourcingLocationAnalytics from "../analytics/sourcingLocation";
import Spacing, { SPACING } from "../components/spacing/Spacing";
import ReportProductLink from "../components/reportProductLink/ReportProductLink";
import RestockingSoonBanner from "../components/restockingSoonBanner/RestockingSoonBanner";
import styles from "./SingleProductPage.scss";

const OutOfStockHero = loadable(
  () => import(/* webpackChunkName: "deferred" */ "../LoadableComponents"),
  {
    ssr: false,
    resolveComponent: ({ OutOfStockHero }) => OutOfStockHero,
  }
);

const App = loadable(
  () => import(/* webpackChunkName: "deferred" */ "../LoadableComponents"),
  {
    ssr: false,
    resolveComponent: ({ App }) => App,
  }
);

const SocialShare = loadable(
  () => import(/* webpackChunkName: "deferred" */ "../LoadableComponents"),
  {
    ssr: false,
    resolveComponent: ({ SocialShare }) => SocialShare,
  }
);

export default function SingleProductPage() {
  const { products } = useStockPrice();
  const { product } = useRuntimeConstants();
  const { isInStock } = useIsInStockContext();

  const mainProducts = products ? [products] : [];

  return (
    <>
      <ProductContextProvider products={mainProducts} productId={product.id}>
        <VariantContextProvider
          fireSourcingLocationAnalytics={fireSourcingLocationAnalytics}
        >
          {getWindow()
            ? ReactDOM.createPortal(
                <OutOfStockHero />,
                getWindow().document.getElementById("pdp-oos-hero")
              )
            : null}
          <LiveRegion />
          <ClientOnly>
            <ProductColourContainer position={PRODUCT_COLOUR_POSITIONS.TOP} />
          </ClientOnly>
          <RepeatedTextBanner />
          <div className={styles.titleContainer}>
            <Spacing size={SPACING.TWELVE} excludeFirst={true}>
              <ShowOnProductStockStatus productStockStatus={false}>
                <OutOfStockLabel />
              </ShowOnProductStockStatus>
              <ProductName />
            </Spacing>
            <SocialShare />
          </div>
          <Spacing size={SPACING.TWELVE}>
            <StockPriceRetry />
            <PriceData />
            <SkeletonPrimaryActions />
            <RatingComponent />
          </Spacing>
          <Spacing size={SPACING.SIXTEEN}>
            <RestockingSoonBanner />
          </Spacing>
          <Spacing size={SPACING.TWENTY}>
            <ProductPromo />
            <ReleaseDateBanner />
            <ClientOnly>
              <ProductColourContainer
                position={PRODUCT_COLOUR_POSITIONS.MIDDLE}
              />
              <IfInStock>
                <CustomerFitRating />
              </IfInStock>
            </ClientOnly>
            <AsosWorldBox />
            <SizingContextProvider>
              <IfInStock>
                <ProductSize />
              </IfInStock>
              {!product?.isDiscontinued ? (
                <IfStockStateReady>
                  <VariantSelectionContainer
                    isInStock={isInStock}
                    shouldShowSave={true}
                    fireAddToBagAnalytics={fireAddToBagAnalytics}
                    fireAddToBagErrorAnalytics={fireAddToBagErrorAnalytics}
                    fireAddToBagMarketing={fireAddToBagMarketing}
                    fireNotifyMeClickAnalytics={fireNotifyMeClickAnalytics}
                    isLooksProduct={false}
                  />
                </IfStockStateReady>
              ) : null}
              <IfInStock>
                <DeliveryAndReturnsReactContainer />
              </IfInStock>
              <ProductDescription />
            </SizingContextProvider>
          </Spacing>
          <IngredientsContainer />
          <ProductCode />
          <ReportProductLink />
        </VariantContextProvider>
      </ProductContextProvider>

      {getWindow()
        ? ReactDOM.createPortal(
            <ErrorBoundary>
              <App />
            </ErrorBoundary>,
            getWindow().document.getElementById("pdp-react-app")
          )
        : null}
    </>
  );
}
