import { useMemo } from "react";
import { formatDateForLoyalty } from "../../utils/formatDateForLoyalty";
import { CUSTOMER_ACCESS_PURCHASE } from "../../constants";

export const useTranslatedText = (getTranslation, customerAccess, endDate) => {
  return useMemo(() => {
    if (!customerAccess || customerAccess !== CUSTOMER_ACCESS_PURCHASE) {
      return "";
    }

    const releaseBannerTranslation = getTranslation(
      "plp_banner_header_early_access"
    );

    if (releaseBannerTranslation) {
      const formattedEndDate = endDate ? formatDateForLoyalty(endDate) : "";
      return {
        releaseBannerTranslation,
        formattedEndDate,
      };
    }

    return "";
  }, [getTranslation, customerAccess, endDate]);
};
