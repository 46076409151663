import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useTranslatedText } from "./useTranslatedText";
import { getCustomerLoyaltyAccess } from "../../utils/getCustomerLoyaltyAccess";
import styles from "./ReleaseDateBanner.scss";

const classnames = classnamesBind.bind(styles);

const ReleaseDateBanner = () => {
  const { getTranslation, product } = useRuntimeConstants();
  const { customerAccess, endDate } = getCustomerLoyaltyAccess(product);

  const textToRender = useTranslatedText(
    getTranslation,
    customerAccess,
    endDate
  );

  if (!textToRender) {
    return null;
  }

  const accessClass = customerAccess ? `access-${customerAccess}` : "";

  return (
    <div className={classnames(styles.releaseDateBanner, styles[accessClass])}>
      {textToRender.releaseBannerTranslation}:{" "}
      <span className={styles.date}>{textToRender.formattedEndDate}</span>
    </div>
  );
};

export default ReleaseDateBanner;
