export const formatDateForLoyalty = (isoDateString) => {
  const date = new Date(isoDateString);

  const dayName = new Intl.DateTimeFormat("en-GB", { weekday: "short" })
    .format(date)
    .toUpperCase();
  const day = date.getDate().toString().padStart(2, "0");
  const monthName = new Intl.DateTimeFormat("en-GB", { month: "short" })
    .format(date)
    .toUpperCase();
  const time = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZoneName: "short",
  })
    .format(date)
    .split(" ");

  return `${dayName} ${day} ${monthName}, ${time[0]}`;
};
